<template>
  <main data-aos="fade-up" data-aos-duration="1000">
    <!-- hero-area start -->
    <section class="breadcrumb-bg pt-100 pb-100">
      <div class="container">
        <div class="row">
          <div class="col-lg-9 col-md-9 col-12">
            <div class="page-title">
              <h1>Appointment</h1>
            </div>
          </div>
          <div class="col-lg-3 col-md-3 d-flex justify-content-start justify-content-md-end align-items-center">
            <div class="page-breadcumb">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb ">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">Appointment</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="appointment-area appointment-area-3 pos-rel pt-115 pb-200">
      <div class="container">
        <div class="row">
          <div class="col-xl-7 col-lg-8">
            <div class="calculate-box white-bg" >
              <div id="mc_embed_signup">
                <form action="https://healthtravelsecure.us3.list-manage.com/subscribe/post?u=501fac33b46103e5ca7d29055&amp;id=b2bad58b09" class="calculate-form appointment-form-3 mb-20 validate" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" target="_blank" novalidate @submit="submitForm">
                  <div id="mc_embed_signup_scroll" class="calculate-content">
                    <div class="row">
                      <div class="col-xl-12">
                        <div class="about-title news-letter-title mb-50">
                          <h1>Schedule A Consultation</h1>
                        </div>
                      </div>
                    </div>
                    <div class="form-group mc-field-group">
                      <input type="text" value="" placeholder="Name" name="FNAME" class="required" id="mce-FNAME">
                      <i class="fas fa-user"></i>
                    </div>
                    <div class="form-group mc-field-group">
                      <input type="email" value="" placeholder="Email Address" name="EMAIL" class="required email" id="mce-EMAIL">
                      <i class="fas fa-envelope"></i>
                    </div>
                    <div class="form-group size1of2">
                      <VuePhoneNumberInput v-model="user.phone" name="PHONE" id="mce-PHONE"/>
                      <i class="fas fa-phone"></i>
                    </div>
                    <div class="form-group">
                      <label for="mce-MMERGE3">Preferred method of contact </label>
                      <select name="MMERGE3" class="select form-control" id="mce-MMERGE3">
                        <option value="Email">Email</option>
                        <option value="phone">phone</option>
                        <option value="Visit">Visit</option>

                      </select>
                      <i class="fas fa-sort-down" style="top: 54px;"></i>
                    </div>
                    <div class="inputGroup">
                      <input id="option1" name="group[26461][1]" v-model="subscribe" type="checkbox"/>
                      <label for="option1">Subscribe to newsletter</label>
                    </div>
                    <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_501fac33b46103e5ca7d29055_b2bad58b09" tabindex="-1" value=""></div>
                    <div id="mce-responses" class="clear">
                      <div class="response" id="mce-error-response" style="display:none"></div>
                      <div class="response" id="mce-success-response" style="display:none"></div>
                    </div>
                  </div>
                  <div class="clear">
                    <input type="submit" value="Request Consultation" name="subscribe" id="mc-embedded-subscribe" class="button btn mt-40">
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
    import mailchimpScript from '../services/mailchimp';
    import VuePhoneNumberInput from 'vue-phone-number-input';
    import 'vue-phone-number-input/dist/vue-phone-number-input.css';

    export default {
        name: "appointment",
        data() {
            return {
                user: {
                    phone: "",
                },
                subscribe: true,
            };
        },
        components: {
            VuePhoneNumberInput
        },
        async created() {
            window.scrollTo(0, 0);
            this.loadMailchimpScript();
        },
        methods:{
          loadMailchimpScript() {
            const script = document.createElement('script');
            script.src = mailchimpScript.src;
            document.head.appendChild(script);
         },
         async submitForm() {
          const nameInput = document.getElementById('mce-FNAME');
          const emailInput = document.getElementById('mce-EMAIL');
          const name = nameInput.value.trim();
          const email = emailInput.value.trim();
          if (name === '' || email === '') {
             return;
          }
          else{
            await this.loadMailchimpScript()
            document.getElementById('mc-embedded-subscribe-form').submit();
            nameInput.value = '';
            emailInput.value = '';
            this.user.phone = '';
            setTimeout(()=>{
              nameInput.classList.remove('mce_inline_error');
              emailInput.classList.remove('mce_inline_error');
              const errorMessage = document.querySelectorAll('[for="mce-FNAME"], [for="mce-EMAIL"]');
              errorMessage.forEach(message => {
                message.textContent = '';
              });
            },500)
          }
        }
      },
    }
</script>

<style scoped>
  #mc_embed_signup input.mce_inline_error {
    border-color: red;
  }
  .form-group {
    position: relative;
  }
  #mce-success-response {
    border: 2px solid #18a34657;
    padding: 10px 20px;
    margin-top: 20px;
    color: #18a346;
    font-weight: 500;
    background: #18a3461f;
  }
  #mce-error-response {
    color: red;
  }

  .mce-PHONE input {
    border-color: initial !important; 
}
  .i-am-not-robot {
    position: fixed !important;
    top: -100000px;
    left: -100000px;
    height: 0;
    width: 0;
    opacity: 0;
  }
  .invalid-feedback {
    display: block;
  }
  .breadcrumb-bg {
    background-image: url("../assets/img/page/page-bg.jpg");
  }
  .appointment-area {
    background-image: url("../assets/img/appoinment/nurse.jpg");
  }
  .appointment-area form .btn {
    width: 100%;
    box-shadow: none;
    border: none;
  }
  .appointment-area form input, .appointment-area form select {
    border-radius: 0;
    box-shadow: none;
  }
  .calculate-box .nice-select-2 .list {
    left: 15px;
    right: 15px;
    border-radius: 0;
  }
  .calculate-form select {
    height: 70px;
    padding-left: 38px;
    width: 100%;
    border: 2px solid #d3e2f0;
    -moz-appearance:none; /* Firefox */
    -webkit-appearance:none; /* Safari and Chrome */
    appearance:none;
  }
  .nice-select {
    margin-bottom: 0;
  }
  .nice-select-2.open .list {
    opacity: 1;
    pointer-events: auto;
    transform: scale(1) translateY(0);
  }
  .appointment-area-3 .calculate-box .nice-select {
    margin-bottom: .25rem;
  }
  .nice-select-2 .list {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 0 1px rgba(68, 68, 68, 0.11);
    box-sizing: border-box;
    margin-top: 4px;
    opacity: 0;
    overflow: hidden;
    padding: 0;
    pointer-events: none;
    position: absolute;
    top: 100%;
    left: 0;
    transform-origin: 50% 0;
    transform: scale(0.75) translateY(-21px);
    transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
    z-index: 9;
  }
  .nice-select-2 .option {
    cursor: pointer;
    font-weight: 400;
    line-height: 40px;
    list-style: none;
    min-height: 40px;
    outline: none;
    padding-left: 18px;
    padding-right: 29px;
    text-align: left;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
  }
  .nice-select-2 .option:hover, .nice-select-2 .option.focus, .nice-select-2 .option.selected.focus {
    background-color: #f6f6f6;
  }
  .nice-select-2 .option.selected {
    font-weight: bold;
  }

  .inputGroup {
    background-color: #fff;
    display: block;
    margin: 10px 0;
    position: relative;
  }
  .inputGroup label {
    border: 2px solid #d3e2f0;
    padding: 22.5px 30px 22.5px 38px;
    width: 100%;
    display: block;
    text-align: left;
    color: #647589;
    cursor: pointer;
    position: relative;
    z-index: 0;
    transition: color 200ms ease-in;
    overflow: hidden;
  }
  .inputGroup label:before {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    content: '';
    background-color: #18a3462b;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale3d(1, 1, 1);
    transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 0;
    z-index: -1;
  }
  .inputGroup label:after {
    width: 32px;
    height: 32px;
    content: '';
    border: 2px solid #d1d7dc;
    background-color: #fff;
    background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E ");
    background-repeat: no-repeat;
    background-position: 2px 3px;
    border-radius: 50%;
    z-index: 2;
    position: absolute;
    right: 18px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    transition: all 200ms ease-in;
  }
  .inputGroup input:checked ~ label:before {
    transform: translate(-50%, -50%) scale3d(56, 56, 1);
    opacity: 1;
  }
  .inputGroup input:checked ~ label:after {
    background-color: #18a346;
    border-color: #18a346;
  }
  .inputGroup input {
    width: 32px;
    height: 32px;
    order: 1;
    z-index: 2;
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    visibility: hidden;
  }

</style>
